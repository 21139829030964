import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Badge from 'shopper/components/Badge';
import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import useSessionStorageState from 'hooks/useSessionStorageState';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';
import { landingQueryKeys } from 'lib/queryKeys';

import { getLandingsList } from 'services/landing';

import CategoriesDropdownButton from './CategoriesDropdownButton';
import StoresDropdownButton from './StoresDropdownButton';

import LINKS from 'constants/links';
import SESSION_STORAGE from 'constants/sessionStorage';

const FeaturedLandingLink = dynamic(() => import('./FeaturedLandingLink'), {
  ssr: true,
});

const MAIN_BUTTONS_LIST = [
  {
    href: LINKS.BLOG,
    text: placeholder('actions.seeBlog'),
    gaEventAction: 'topbar_blog_tab',
    testSelectorAttr: 'blog-tab',
    icon: <Icon name="article" />,
    isSponsored: false,
  },
  {
    href: LINKS.COUPONS,
    text: placeholder('actions.seeCoupons'),
    gaEventAction: 'topbar_coupon_tab',
    testSelectorAttr: 'coupons-tab',
    icon: <Icon name="discount-filled" />,
    isSponsored: false,
  },
  {
    href: LINKS.FORUM,
    text: placeholder('actions.seeForum'),
    gaEventAction: 'topbar_forum_tab',
    testSelectorAttr: 'forum-tab',
    icon: <Icon name="messages" />,
    isSponsored: false,
  },
  {
    href: LINKS.ALIEXPRESS_FIRST_PURCHASE_DEALS,
    text: placeholder('actions.seeAliexpressSponsored'),
    gaEventAction: 'aliexpress_sponsor_tab',
    testSelectorAttr: 'aliexpress_sponsor_tab',
    icon: <Icon name="aliexpress" />,
    isSponsored: true,
  },
];

const TopbarBottomDefaultNav = ({ gaEventCategory }) => {
  const [isSponsorBeenVisited, setSponsoredBeenVisited] =
    useSessionStorageState(SESSION_STORAGE.IS_SPONSOR_VISITED, false);

  const { data: featuredLandings } = useQuery({
    queryKey: landingQueryKeys.featured(),
    queryFn: ({ signal }) => getLandingsList({ featured: true }, { signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <CategoriesDropdownButton gaEventCategory={gaEventCategory} />
      <StoresDropdownButton gaEventCategory={gaEventCategory} />
      {MAIN_BUTTONS_LIST.map(
        ({
          gaEventAction,
          href,
          icon,
          isSponsored,
          testSelectorAttr,
          text,
        }) => {
          const showBadge = isSponsored && !isSponsorBeenVisited;
          const Item = (
            <Button
              key={href}
              as="a"
              className="relative mr-4"
              data-test-selector={testSelectorAttr}
              href={isSponsored ? href : null}
              iconLeft={icon}
              rel={isSponsored ? 'noopener noreferrer' : null}
              size="size4"
              target={isSponsored ? '_blank' : null}
              type="neutral-ghost"
              onClick={() => {
                if (isSponsored) {
                  setSponsoredBeenVisited(true);
                }

                sendEvent({
                  action: gaEventAction,
                  category: gaEventCategory,
                });
              }}
            >
              {text}
              {showBadge && (
                <Badge
                  className="absolute right-0 top-1"
                  shape="dot"
                  type="highlight"
                />
              )}
            </Button>
          );

          if (isSponsored) {
            return Item;
          }

          return (
            <NextLink key={href} href={href} prefetch={false} passHref>
              {Item}
            </NextLink>
          );
        }
      )}
      {featuredLandings.map(
        ({ landingId, landingSlug, landingName, landingType }, index) => (
          <FeaturedLandingLink
            key={landingId}
            gaEventCategory={gaEventCategory}
            landingId={landingId}
            landingName={landingName}
            landingSlug={landingSlug}
            landingType={landingType}
            position={index + 0}
          />
        )
      )}
    </>
  );
};

TopbarBottomDefaultNav.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default TopbarBottomDefaultNav;
