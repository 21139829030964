import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { memo, useMemo, useState } from 'react';

import { Dropdown } from 'shopper/components/Dropdown';

import {
  toCategoryLink,
  toLandingPageLink,
  toSubcategoryLink,
} from 'lib/links';
import { landingQueryKeys, offerQueryKeys } from 'lib/queryKeys';
import { isBlackFridayLanding } from 'lib/utils';

import { getCategoriesList } from 'services/category';
import { getLandingsList } from 'services/landing';

import CategoriesGroups from './CategoriesGroups';
import SitemapGroupList from './SitemapGroupList';

const ActiveCategoryGroupList = dynamic(
  () => import('./ActiveCategoryGroupList'),
  { ssr: false }
);

const CategoriesDropdown = ({ isOpen, openerRef, onClose }) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const { data: highlightedLandings } = useQuery({
    queryKey: landingQueryKeys.highlighted(),
    queryFn: ({ signal }) => getLandingsList({ highlighted: true }, { signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: categories } = useQuery({
    queryKey: offerQueryKeys.categories({ includeOfferSelections: true }),
    queryFn: ({ signal }) =>
      getCategoriesList({ selectedCategories: true }, { signal }),
    enabled: false,
    staleTime: Infinity,
  });

  const { data: featuredLandings } = useQuery({
    queryKey: landingQueryKeys.featured(),
    queryFn: ({ signal }) => getLandingsList({ featured: true }, { signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const categoriesWithHighlightedLandings = useMemo(() => {
    const categoriesWithUrl = categories.map((category) => ({
      ...category,
      categoryUrl: toCategoryLink(category.categoryUrl),
      subcategories: category.subcategories.map((s) => ({
        ...s,
        subcategoryUrl: toSubcategoryLink(s.subcategoryParam),
      })),
    }));

    if (!featuredLandings.length === 0 || highlightedLandings.length === 0) {
      return categoriesWithUrl;
    }

    if (featuredLandings.some(isBlackFridayLanding)) {
      return categoriesWithUrl.concat(
        highlightedLandings.map(
          ({ landingName, landingSlug, landingType }) => ({
            categoryName: landingName,
            categoryUrl: toLandingPageLink(landingSlug, landingType),
            groupName: 'Black Friday',
            subcategories: [],
          })
        )
      );
    }

    return categoriesWithUrl.concat([
      {
        categoryName: 'Páginas Especiais',
        categoryUrl: '',
        groupName: 'Diversos',
        subcategories: highlightedLandings.map(
          ({ landingName, landingSlug, landingType }) => ({
            subcategoryUrl: toLandingPageLink(landingSlug, landingType),
            subcategoryName: landingName,
          })
        ),
      },
    ]);
  }, [categories, highlightedLandings, featuredLandings]);

  const onCloseHandler = (e) => {
    setActiveCategory(null);
    onClose(e);
  };

  return (
    <>
      {isOpen && (
        <Dropdown
          className="rounded-3 bg-neutral-high-100 shadow-[0px_0px_10px_0px_rgba(87,87,87,0.2)] dark:border dark:bg-neutral-low-500"
          data-test-selector="categories-dropdown"
          id="categories-dropdown"
          open={isOpen}
          openerRef={openerRef}
          renderToHTML
          onClickOutside={onClose}
        >
          <ul className="flex" onMouseLeave={onCloseHandler}>
            <CategoriesGroups
              activeCategory={activeCategory}
              categories={categoriesWithHighlightedLandings}
              onSelect={setActiveCategory}
            />
            {activeCategory && (
              <ActiveCategoryGroupList
                activeCategory={activeCategory}
                categories={categoriesWithHighlightedLandings}
                onClose={onCloseHandler}
              />
            )}
          </ul>
        </Dropdown>
      )}
      {/* This is used for SEO purposes... */}
      <SitemapGroupList categories={categoriesWithHighlightedLandings} />
    </>
  );
};

CategoriesDropdown.propTypes = {
  activeCategory: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  openerRef: PropTypes.shape().isRequired,
  setActiveCategory: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(CategoriesDropdown);
